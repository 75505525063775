<template>
  <div class="page__wrapper">
    <ag-grid
      ref="reportReviewGrid"
      style="width: 100%; height: 100%"
      pagination
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :pagination-page-size="tablePageInfo.pageSize"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t("operation.search") }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <!-- 详情页 -->
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
    <!-- 详情页 New -->
    <new-detail-dialog
      :show-flag.sync="isShowNewDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
    <!-- 详情页 2022 -->
    <detail-2022-dialog
      :show-flag.sync="isShowDetail2022Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
    <!-- 详情页 202206 -->
    <detail-202206-dialog
      :show-flag.sync="isShowDetail202206Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
    <!-- 详情页 202308 -->
    <detail-202308-dialog
      :show-flag.sync="isShowDetail202308Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <anomaly
      :show-flag.sync="isShowAnomalyDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import anomaly from './anomaly'
import detailDialog from '../../dealer/finance/detail'
import newDetailDialog from '../../dealer/finance/detailNew'
import detail2022Dialog from '../../dealer/finance/detail2022'
import detail202206Dialog from '../../dealer/finance/detail202206'
import detail202308Dialog from '../../dealer/finance/detail202308'
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/selfExaminationData/list',
  office: '/system/office/list',
  submit: '/jlr/tt/selfExaminationData/submitData'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: [
    'handleDataSearch',
    'handleViewClick',
    'handleCheckClick',
    'handleAjustEntriesClick',
    'handleSubmitClick'
  ],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.preview')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-search" v-permission="'dealer_anomaly:view'"
          @click="handleViewClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('dealer_anomaly.anomalyExplain')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-s-data" v-permission="'dealer_anomaly:explain'"
          @click="handleCheckClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('dealer_anomaly.dataModifyAjustEntry')">
        <el-button type="text" icon="el-icon-s-open" v-permission="'dealer_anomaly:ajustEntries'"
          :disabled="params.data.isSubmit==='Y'" @click="handleAjustEntriesClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('operation.submit')">
        <el-button type="text" icon="el-icon-success" v-permission="'dealer_anomaly:submit'" :disabled="params.data.isSubmit==='Y'"
        @click="handleSubmitClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'Anomaly',
  components: {
    detailDialog,
    anomaly,
    newDetailDialog,
    detail2022Dialog,
    detail202206Dialog,
    detail202308Dialog
  },
  mixins: [ pageMixins ],
  data () {
    return {
      searchModel: {
        period: this.$moment().subtract(1, 'months').format('YYYYMM')
      },
      tableData: [],
      rowData: {},
      editable: false,
      dealerList: [],
      isViewFlag: false,
      isShowDetailDialog: false,
      isShowAnomalyDialog: false,
      gridOptions: {},
      frameworkComponents: {},
      submitting: false,
      isShowNewDetailDialog: false,
      isShowDetail2022Dialog: false,
      isShowDetail202206Dialog: false,
      isShowDetail202308Dialog: false
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleViewClick: this.handleViewClick,
      handleCheckClick: this.handleCheckClick,
      handleSubmitClick: this.handleSubmitClick,
      handleAjustEntriesClick: this.handleAjustEntriesClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.month'),
          component: { type: 'month', valueFormat: 'yyyyMM', clearable: false }
        },
        {
          slotName: 'tableOperation',
          col: { xs: 20, sm: 20, md: 20 },
          style: { textAlign: 'right' },
          labelWidth: '0'
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.dealerName'),
          field: 'dealer.name',
          pinned: 'left',
          minWidth: 260
        },
        {
          headerName: this.$t('dealer_finance.dealerCode'),
          field: 'dealer.code',
          pinned: 'left',
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.area'),
          field: 'region',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'sys_office_region',
              value: params.value
            }),
          minWidth: 80
        },
        {
          headerName: this.$t('dealer_finance.group'),
          field: 'dealerParent',
          valueFormatter: (params) =>
            params.data.dealerParent ? params.data.dealerParent.name || '' : '',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return valueA && valueB
              ? valueA.name.localeCompare(valueB.name)
              : -1
          },
          minWidth: 150
        },
        {
          headerName: this.$t('dealer_finance.version'),
          field: 'version',
          minWidth: 130
        },
        {
          headerName: this.$t('dealer_finance.cycle'),
          field: 'period',
          valueFormatter: (params) =>
            params.value
              ? this.$moment(params.value, 'YYYYMM').format('YYYY年MM月')
              : '',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.reporter'),
          field: 'compiler',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.reporterPosition'),
          field: 'compilerPosition',
          minWidth: 120
        },
        // {
        //   headerName: this.$t('dealer_finance.reporterPhone'),
        //   field: 'compilerPhone',
        //   minWidth: 120
        // },
        {
          headerName: this.$t('dealer_finance.reporterEmail'),
          field: 'compilerMail',
          minWidth: 260
        },
        {
          headerName: this.$t('dealer_finance.createDate'),
          field: 'updateDate',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.status'),
          field: 'bizStatus',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'tt_self_examination_data_head_biz_status',
              value: params.value
            }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.isSubmit'),
          field: 'isSubmit',
          valueFormatter: (params) =>
            this.$getDictLabel({ type: 'yes_no', value: params.value }),
          minWidth: 120
        },
        {
          headerName: this.$t('field.operation'),
          width: 150,
          suppressSizeToFit: true,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.getDealerList()
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign(
        {},
        this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      )
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      params.filter.push({ left: 'bizStatus', operate: '=', right: 'V2' })
      this.$axios
        .post(BASEURL.list, params)
        .then((resp) => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleViewClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = true
      if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202101', 'YYYYMM')
      ) {
        this.isShowDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202201', 'YYYYMM')
      ) {
        this.isShowNewDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202206', 'YYYYMM')
      ) {
        this.isShowDetail2022Dialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202308', 'YYYYMM')
      ) {
        this.isShowDetail202206Dialog = true
      } else {
        this.isShowDetail202308Dialog = true
      }
    },
    handleCheckClick (row) {
      this.isViewFlag = row.isSubmit === 'Y'
      this.rowData = this.$_.cloneDeep(row)
      this.isShowAnomalyDialog = true
    },
    handleAjustEntriesClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = false
      if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202101', 'YYYYMM')
      ) {
        this.isShowDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202201', 'YYYYMM')
      ) {
        this.isShowNewDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202206', 'YYYYMM')
      ) {
        this.isShowDetail2022Dialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202308', 'YYYYMM')
      ) {
        this.isShowDetail202206Dialog = true
      } else {
        this.isShowDetail202308Dialog = true
      }
    },
    handleSubmitClick (row) {
      if (this.submitting) return
      this.submitting = true
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('dealer_finance.submitTip'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done()
            const loadingFlag = this.$loading({
              fullscreen: false,
              target: this.$el,
              text: this.$t('tip.systemLoading')
            })
            this.$axios
              .post(BASEURL.submit, { id: row.id, copyNewVersion: true })
              .then((resp) => {
                const messages = resp.data.itemList
                if (!messages || (messages && !messages.length)) {
                  this.$message({
                    type: 'success',
                    message: this.$t('tip.approveSuccess')
                  })
                } else {
                  let str = ''
                  messages.forEach((ele) => {
                    str +=
                      '<div style="padding:8px;">' + ele.message + '</div>'
                  })
                  this.$message({
                    type: 'error',
                    duration: 0,
                    offset: 100,
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: str
                  })
                }
              })
              .finally(() => {
                this.submitting = false
                loadingFlag.close()
                this.handleDataSearch()
              })
          } else {
            done()
            this.submitting = false
          }
        }
      }).then(() => { })
    },
    getDealerList (name) {
      this.dealerLoading = true
      const params = {
        page: { pageNo: 1, pageSize: 10 },
        filter: [
          { left: 'category', operate: '=', right: 'sys_office_category_3' }
        ]
      }
      this.$axios
        .post(BASEURL.office, params)
        .then((resp) => {
          const respData = resp.data
          this.dealerList = respData.list.map((item) => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.dealerLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>
